import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useHistory} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import SvgIcon from '@material-ui/core/SvgIcon'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as AppIcon } from '../images/app-icon.svg'
import Menu from './Menu'
import User from './User'
import {AuthContext} from '../contexts/AuthContext'
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'


const Shell = ({children, toolbarRender, ...props}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()
  const {isAuth} = React.useContext(AuthContext)

  const handleClick = (event) => {
    if(isAuth) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <SvgIcon>
            <AppIcon/>
          </SvgIcon>
          <Typography variant="h6" color="inherit" noWrap onClick={() => history.push('/')}>
            MsgSync
          </Typography>
          <div className={classes.grow} />
          <IconButton
            aria-controls="app-menu"
            aria-owns={anchorEl ? 'app-menu' : undefined}
            aria-haspopup="true"
            color="inherit"
            aria-label="Menu"
            onClick={handleClick}
          >
            <User />
          </IconButton>
          <Menu id="app-menu" anchorEl={anchorEl} handleClose={handleClose}/>
        </Toolbar>
        {toolbarRender}
      </AppBar>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => history.push('/')}
      >
        {children}
      </ErrorBoundary>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1
  }
})

export default Shell