import React, { useEffect } from 'react'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import { useSnackbar } from 'notistack'
import { ApiContext } from './ApiContext'
import eatError from './hooks/eatError'
import snackbarErrorMessage from './hooks/snackbarErrorMessage'

const AppSettingsContext = React.createContext()
const ALLOW_REGISTRATION = 'allowRegistration'
const SMTP_SETTINGS = 'smtpSettings'
const SET_SETTINGS = 'setSettings'
const SET_PRUNE_DAYS = 'pruneSettings'

const initialState = {
  defaultState: true, // Once this property has been removed the app has successfully fetched the settings from the server
  allowRegistration: false,
  pruneAfterDays: 14,
}

const reducer = (state, action) => {
  // console.log('Settings Reducer', state, action)
  switch (action.type) {
    case ALLOW_REGISTRATION:
      return { ...state, allowRegistration: action.payload }
    case SMTP_SETTINGS:
      return { ...state, smtpSettings: action.payload }
    case SET_SETTINGS:
      return action.payload
    case SET_PRUNE_DAYS:
      return { ...state, pruneAfterDays: action.payload }
    default:
      return state
  }
}

const AppSettingsProvider = ({ children }) => {
  const { appSettingsApi } = React.useContext(ApiContext)
  const { enqueueSnackbar } = useSnackbar()
  const [settings, dispatch] = React.useReducer(reducer, initialState)

  console.log('App Settings', settings)

  appSettingsApi.hooks({
    error: {
      all: [snackbarErrorMessage(enqueueSnackbar)],
      find: [eatError(initialState)],
    },
  })

  const setSettings = (settings) => {
    dispatch({
      type: SET_SETTINGS,
      payload: mapValues(keyBy(settings, '_id'), 'value'),
    })
  }

  useEffect(() => {
    appSettingsApi.find().then((results) => {
      console.log('Fetched settings', results.data)
      setSettings(results.data)
    })
  }, [appSettingsApi])

  const updateRegistration = async (value) => {
    console.log('updateRegistration set to ', value)

    const response = await appSettingsApi.update('allowRegistration', {
      value,
    })

    console.log('allow reg response', response)

    dispatch({
      type: ALLOW_REGISTRATION,
      payload: response.value,
    })
  }

  const updatePruneAfterDays = async (value) => {
    if (settings.pruneAfterDays !== value) {
      console.log('Settings.setPruneAfterDays', value)

      try {
        const response = await appSettingsApi.update('pruneAfterDays', {
          value,
        })

        dispatch({
          type: SET_PRUNE_DAYS,
          payload: response.value,
        })
      } catch (error) {
        console.error(error)
        enqueueSnackbar(error.message)
      }
    }
  }

  return (
    <AppSettingsContext.Provider
      value={{
        settings,
        updateRegistration,
        updatePruneAfterDays,
      }}>
      {children}
    </AppSettingsContext.Provider>
  )
}

const AppSettingsConsumer = AppSettingsContext.Consumer

export { AppSettingsProvider, AppSettingsConsumer, AppSettingsContext }
