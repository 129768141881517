import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { AuthContext } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import usePushNotification from './hooks/usePushNotification'

const AppMenu = ({ handleClose, anchorEl, id, ...otherProps }) => {
  const { isAuth, logout } = React.useContext(AuthContext)
  const { enableNotification } = usePushNotification()

  const handleNotificationClick = () => {
    console.log('Notification Clicked')
    handleClose()
    enableNotification()
  }

  const handleLogoutClick = () => {
    handleClose()
    logout()
  }

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && isAuth}
      onClose={handleClose}
      {...otherProps}>
      <MenuItem onClick={handleNotificationClick}>
        Enable Notifications
      </MenuItem>
      <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      <MenuItem component={Link} to="/settings" onClick={handleClose}>
        Settings
      </MenuItem>
      <MenuItem component={Link} to="/docs" onClick={handleClose}>
        API Docs
      </MenuItem>
    </Menu>
  )
}

export default AppMenu
