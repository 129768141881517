import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import {AuthContext} from '../contexts/AuthContext'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const User = () => {
  const {isAuth} = React.useContext(AuthContext)
  return (
    <Avatar alt="user">
      {!isAuth ? <AccountCircleIcon/> : <AccountCircleIcon/>}
    </Avatar>
  )
}

export default User