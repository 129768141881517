import React from 'react'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import auth from '@feathersjs/authentication-client'
import { useSnackbar } from 'notistack'

const ApiContext = React.createContext()

const app = feathers()
const restClient = rest()
// Configure feathers to use fetch in modern browsers
app.configure(restClient.fetch(window.fetch))
//Configure Authentication for the client app
app.configure(
  auth({
    storage: localStorage,
    path: '/authentication',
    storageKey: 'accessToken',
  })
)

const adminSettingsApi = app.service('/api/admin-settings')
const appSettingsApi = app.service('/api/app-settings')
const userSettingsApi = app.service('/api/user-settings')
const sharesApi = app.service('/api/shares')
const usersApi = app.service('/api/users')

const ApiProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  //
  // app.hooks({
  //   error: snackbarErrorMessage(enqueueSnackbar),
  // })

  const updateAdminSetting = async (id, value) => {
    try {
      const response = await adminSettingsApi.update(id, value)
      enqueueSnackbar(`Updated Admin Setting: ${id}`, { variant: 'success' })
      console.log('updateAdminSetting.response', response)
      return {
        type: response._id,
        payload: response,
      }
    } catch (err) {
      enqueueSnackbar(`Failed Updating Admin Setting ${id}: ${err.message}`, {
        variant: 'error',
      })
    }
  }

  return (
    <ApiContext.Provider
      value={{
        app,
        appSettingsApi,
        adminSettingsApi,
        userSettingsApi,
        sharesApi,
        usersApi,
        updateAdminSetting,
      }}>
      {children}
    </ApiContext.Provider>
  )
}

export { ApiProvider, ApiContext }
