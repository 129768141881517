import React from 'react'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import PersonIcon from '@material-ui/icons/Person'
import Lock from '@material-ui/icons/Lock'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { useSnackbar } from 'notistack'
import { ApiContext } from '../../contexts/ApiContext'
import {useHistory} from 'react-router-dom'

const RegisterForm = ({ ...otherProps }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { usersApi } = React.useContext(ApiContext)
  const history = useHistory()

  return (
    <div className={classes.loginContainer}>
      <Paper className={classes.paper}>
        <Formik
          initialValues={{ email: '', password: '', passwordConfirm: '' }}
          onSubmit={(
            { email, password, passwordConfirm },
            { setSubmitting, setStatus, setErrors, resetForm }
          ) => {
            setSubmitting(true)

            usersApi
              .create({
                email,
                password,
              })
              .then((response) => {
                console.log('Registration Response', response)
                enqueueSnackbar('Registration Complete. Please Login.', {variant: 'success'})
                history.push('/login')
              })
              .catch((error) => {
                console.error('Registration Error', error)
                setErrors(error)
                enqueueSnackbar(error.message, { variant: 'error' })
              })
              .finally(() => {
                console.log('Registration finally complete')
                setSubmitting(false)
                resetForm()
              })
          }}
          validate={(values) => {
            const errors = {}
            if (values.email && !values.email.includes('@')) {
              errors.email = 'Please enter a valid email address'
            }
            if (
              values.password &&
              values.passwordConfirm &&
              values.password !== values.passwordConfirm
            ) {
              errors.passwordConfirm = 'Please confirm your password'
            }
            // console.log('validating register form', values, errors)

            return errors
          }}>
          {({
            values,
            errors,
            dirty,
            isSubmitting,
            isValid,
            status,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.form}>
                  <TextField
                    name="email"
                    value={values.email}
                    label="E-mail"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    name="password"
                    value={values.password}
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    name="passwordConfirm"
                    value={values.passwordConfirm}
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.passwordConfirm)}
                    helperText={
                      errors.passwordConfirm ? errors.passwordConfirm : ''
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.loginBtn}
                    disabled={!isValid || isSubmitting}>
                    Register
                  </Button>

                  <Typography variant="caption">
                    <Link to="/login">Already have an account? Login</Link>
                  </Typography>
                </div>
              </form>
            )
          }}
        </Formik>
      </Paper>
    </div>
  )
}

const useStyles = makeStyles({
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  form: {
    // padding: 20,
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  paper: {
    padding: '2em',
  },
  loginBtn: {
    margin: '1em',
  },
})

export default RegisterForm
