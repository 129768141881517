import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { AuthContext } from './AuthContext'
import debounce from 'lodash/debounce'
import { ApiContext } from './ApiContext'
import snackbarErrorMessage from './hooks/snackbarErrorMessage'

const UserSettingsContext = React.createContext()
const SET_USER_SETTINGS = 'setUserSettings'
const SET_USER_PRUNE_DAYS = 'setUserPruneDays'

const initialState = {
  pruneAfterDays: 8,
  defaultState: true,
}

const reducer = (state, action) => {
  // console.log('User Settings Reducer', state, action)
  switch (action.type) {
    case SET_USER_PRUNE_DAYS:
      return {
        ...state,
        pruneAfterDays: action.payload,
      }
    case SET_USER_SETTINGS:
      return action.payload
    default:
      return state
  }
}

const submitUserSettings = debounce(async (userSettingsApi, userSettings) => {
  // console.log('submitUserSettings.updating...', userSettings)
  const result = await userSettingsApi.update(userSettings._id, userSettings)

  console.log('submitUserSettings.updated:', result)
}, 1000)

const UserSettingsProvider = ({ children }) => {
  // console.log('UserSettingsProvider render')
  const { enqueueSnackbar } = useSnackbar()
  const { isAuth } = React.useContext(AuthContext)
  const { userSettingsApi } = React.useContext(ApiContext)
  const [userSettings, dispatch] = React.useReducer(reducer, initialState)

  userSettingsApi.hooks({
    error: {
      all: [snackbarErrorMessage(enqueueSnackbar)],
    },
  })

  const setPruneAfterDays = (value) => {
    if (userSettings.pruneAfterDays !== value) {
      console.log('UserSettings.setPruneAfterDays', value)

      dispatch({
        type: SET_USER_PRUNE_DAYS,
        payload: value,
      })
    }
  }

  useEffect(() => {
    if (isAuth) {
      userSettingsApi.find().then((result) => {
        console.log('Fetched User settings', result)

        dispatch({
          type: SET_USER_SETTINGS,
          payload: result.data[0],
        })
      })
    }
  }, [isAuth, userSettingsApi])

  useEffect(() => {
    if (!userSettings.defaultState) {
      // console.log('hey the userSettings changed', userSettings)
      submitUserSettings(userSettingsApi, userSettings)
    }
  }, [userSettings, userSettingsApi])

  return (
    <UserSettingsContext.Provider value={{ userSettings, setPruneAfterDays }}>
      {children}
    </UserSettingsContext.Provider>
  )
}

const UserSettingsConsumer = UserSettingsContext.Consumer

export { UserSettingsProvider, UserSettingsConsumer, UserSettingsContext }
