import Alert from '@material-ui/lab/Alert'
import React from 'react'

const ErrorFallback = ({error, componentStack, resetErrorBoundary}) => {
  return (
    <Alert severity="error">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </Alert>
  )
}

export default ErrorFallback