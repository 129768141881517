const tokenKey = 'accessToken'
const userKey = 'user'

// Manages the Authorization header and associated access token
const accessToken = {
  get: () => localStorage.getItem(tokenKey),
  getUser: () => JSON.parse(localStorage.getItem(userKey)),
  clear: () => {
    localStorage.removeItem(tokenKey)
    localStorage.removeItem(userKey)
  },
  set: (accessToken, user) => {
    localStorage.setItem(tokenKey, accessToken)
    localStorage.setItem(userKey, JSON.stringify(user))
  },
  load: () => {
    if(accessToken.get()) {
      accessToken.set(accessToken.get(), accessToken.getUser())
    }
  }
}

export default accessToken