import React from 'react'
import { Formik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import PersonIcon from '@material-ui/icons/Person'
import Lock from '@material-ui/icons/Lock'
import { AuthConsumer } from '../../contexts/AuthContext'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import { AppSettingsConsumer } from '../../contexts/AppSettingsContext'

const LoginForm = ({ classes, history, ...other }) => {
  console.log('LoginForm', other)

  return (
    <div className={classes.loginContainer}>
      <AuthConsumer>
        {({ isAuth, login }) => {
          console.log('login is authed', isAuth)
          return (
            <AppSettingsConsumer>
              {({ settings }) => {
                return (
                  <Paper className={classes.paper}>
                    <Formik
                      initialValues={{ email: '', password: '' }}
                      onSubmit={(
                        { email, password },
                        { setSubmitting, setStatus, setErrors }
                      ) => {
                        // console.log('onSubmit', values)
                        login({
                          email,
                          password,
                          setSubmitting,
                          setStatus,
                          setErrors,
                          history,
                        })
                      }}>
                      {({
                        values,
                        errors,
                        status,
                        handleSubmit,
                        handleChange,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className={classes.form}>
                            <TextField
                              name="email"
                              value={values.email}
                              label="E-mail"
                              fullWidth
                              onChange={handleChange}
                              error={Boolean(status)}
                              helperText={status}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              name="password"
                              value={values.password}
                              label="Password"
                              type="password"
                              fullWidth
                              onChange={handleChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Lock />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.loginBtn}>
                              Login
                            </Button>
                            {settings.allowRegistration ? (
                              <Typography variant="caption">
                                <Link to="/register">
                                  Don't have an account? Register
                                </Link>
                              </Typography>
                            ) : null}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </Paper>
                )
              }}
            </AppSettingsConsumer>
          )
        }}
      </AuthConsumer>
    </div>
  )
}

const style = {
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '20%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
  form: {
    // padding: 20,
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  paper: {
    padding: '2em',
  },
  loginBtn: {
    margin: '1em',
  },
}

export default withStyles(style)(LoginForm)
