import React from 'react'
import { useSnackbar } from 'notistack'
import { ApiContext } from '../../contexts/ApiContext'
import urlBase64ToUint8Array from '../../util/urlBase64ToUint8Array'

function usePushNotification() {
  const { enqueueSnackbar } = useSnackbar()
  const { app } = React.useContext(ApiContext)

  const enableNotification = async () => {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      const result = await Notification.requestPermission()
      console.log('User Choice', result)
      if (result !== 'granted') {
        enqueueSnackbar('No notification permission granted!')
      } else {
        // User has allowed Notifications

        try {
          console.log('config push sub')
          const serviceWorker = await navigator.serviceWorker.ready
          console.log('serviceWorker', serviceWorker)
          const sub = await serviceWorker.pushManager.getSubscription()

          if (sub === null) {
            const { publicKey } = await app.service('/subscriptions').find()
            console.log('get public key response', publicKey)
            const convertedPublicKey = urlBase64ToUint8Array(publicKey)

            // create a new subscription
            const newSub = await serviceWorker.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedPublicKey,
            })

            console.log('Sending new subscription to server', newSub)

            const subResponse = await app
              .service('/subscriptions')
              .create(newSub)

            console.log('response from creating subscription', subResponse)

            if (subResponse) {
              console.log('successfully subscribed for notifications')
              enqueueSnackbar('Subscribed for notifications.')
            }
          } else {
            // we have a subscription
            console.log('we already have a subscription', sub)

            const subResponse = await app.service('/subscriptions').create(sub)

            if (subResponse) {
              console.log('Successfully resubscribed to notifications')
              enqueueSnackbar('Subscribed for notifications.')
            }

            // throw new Error("We already have a subscription")
          }
        } catch (err) {
          console.log('Error configuring push subscription', err)
          enqueueSnackbar('Failed to subscribe to notifications', {
            variant: 'error',
          })
        }
      }
    } else {
      enqueueSnackbar('Notifications not supported')
    }
  }

  return { enableNotification }
}

export default usePushNotification
