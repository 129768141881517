import React from 'react'
import { AuthConsumer, AuthProvider } from './contexts/AuthContext'
import { AppSettingsProvider } from './contexts/AppSettingsContext'
import { UserSettingsProvider } from './contexts/UserSettingsContext'
import { SnackbarProvider } from 'notistack'
import Shell from './components/Shell'
import Login from './features/auth/LoginForm'
import Register from './features/auth/RegisterForm'
import history from './util/history'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorFallback'
import { ApiProvider } from './contexts/ApiContext'
import Loading from '@material-ui/core/LinearProgress'

const Main = React.lazy(() => import('./components/Main'))

const App = () => {
  return (
    <Router history={history}>
      <SnackbarProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => history.push('/')}>
          <ApiProvider>
            <AuthProvider>
              <AppSettingsProvider>
                <UserSettingsProvider>
                  <Shell>
                    <Switch>
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <Route>
                        <AuthConsumer>
                          {({ isAuth }) => {
                            // console.log('App is Authed', isAuth)
                            return isAuth ? <React.Suspense fallback={<Loading/>}><Main /></React.Suspense> : <Redirect to="/login" />
                          }}
                        </AuthConsumer>
                      </Route>
                    </Switch>
                  </Shell>
                </UserSettingsProvider>
              </AppSettingsProvider>
            </AuthProvider>
          </ApiProvider>
        </ErrorBoundary>
      </SnackbarProvider>
    </Router>
  )
}

export default App
