import React, { useState } from 'react'
import accessToken from '../api/accessToken'
import { useHistory } from 'react-router-dom'
import { ApiContext } from './ApiContext'
import { useSnackbar } from 'notistack'

// When there's no Provider, the defaultValue argument is used for function createContext.
// This is helpful for testing components in isolation without wrapping them, or testing it with different values from Provider.
const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const auth = useAuthProvider()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useAuthProvider = () => {
  console.log(`AuthProvider User: ${JSON.stringify(accessToken.getUser())}`)

  const { app } = React.useContext(ApiContext)
  const [user, setUser] = useState(accessToken.getUser())
  const [isAuth, setAuthed] = useState(Boolean(accessToken.get()))
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const logout = () => {
    console.log('AuthContext Logout Started')
    enqueueSnackbar('logging out')
    accessToken.clear()
    setAuthed(false)
    history.replace('/login')
  }

  app
    .reAuthenticate()
    .then(() => {
      enqueueSnackbar('re-authenticated')
    })
    .catch((error) => {
      console.error(error)
      enqueueSnackbar(error.message, { variant: 'warning' })
      logout()
    })

  const isAdmin = (user?.permissions || []).includes('admin')

  const login = ({
    email,
    password,
    setSubmitting,
    setStatus,
    setErrors,
    history,
  }) => {
    setSubmitting(true)

    app
      .authenticate({
        strategy: 'local',
        email: email.trim(),
        password: password.trim(),
      })
      .then((response) => {
        // user comes out here
        console.log('app.authenticate', response)
        accessToken.set(response.accessToken, response.user)
        setAuthed(true)
        setUser(response.user)
        setSubmitting(false)
        history.replace('/')
        enqueueSnackbar('authenticated')
      })
      .catch((error) => {
        console.error('Authentication error', error)
        enqueueSnackbar('failed to authorize user', error)
        setStatus(error.message)
        setSubmitting(false)
      })
  }

  return {
    login,
    logout,
    isAuth,
    user,
    isAdmin,
    accessToken: accessToken.get(),
  }
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer, AuthContext }
